/**
 * Abstraction for localStorage for easier usage when localStorage is disabled (eg. when cookies are disabled).
 */
class ClientStorage {
  constructor() {
    // Check if localStorage is enabled. We use try...catch because just checking if it exists will
    // throw a SecurityError exception (eg. Safari when cookies are disabled).
    try {
      localStorage.getItem('storychief'); // Execute an operation to check if localStorage works.
      this.isEnabled = true;
    } catch {
      this.isEnabled = false;
    }
  }

  /**
   * @param {string} keyName
   * @returns {string|null}
   */
  getItem(keyName) {
    return this.isEnabled ? localStorage.getItem(keyName) : null;
  }

  /**
   * @param {string} keyName
   * @param {string} keyValue
   */
  setItem(keyName, keyValue) {
    if (this.isEnabled) {
      localStorage.setItem(keyName, keyValue);
    }
  }

  /**
   * @param {string} keyName
   */
  removeItem(keyName) {
    if (this.isEnabled) {
      localStorage.removeItem(keyName);
    }
  }
}

export default new ClientStorage();
