import uniqueAppend from '@/graphql/utils/uniqueAppend';

export default function infiniteScrollPagination() {
  return {
    keyArgs: ['url'],
    merge(existingData = { data: [] }, incomingData = { data: [] }, { args }) {
      // Pagination is disabled, use the default merge policy
      if (!args.page) {
        return incomingData;
      }

      // Nothing changed
      if (!incomingData.paginatorInfo) {
        return existingData;
      }

      // Local data is edited (new item, deletion)
      if (existingData.paginatorInfo?.currentPage === incomingData.paginatorInfo?.currentPage) {
        return incomingData;
      }

      // New data is fetched
      return {
        ...incomingData,
        data: uniqueAppend(existingData.data, incomingData.data),
      };
    },
  };
}
