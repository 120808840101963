import { Views } from 'react-big-calendar';
import { InMemoryCache, makeVar } from '@apollo/client';
import {
  STATUS_DRAFT,
  STATUS_DELETED,
  STATUS_IDLE,
  STATUS_PUBLISHED,
  STATUS_UNPUBLISHED,
  STATUS_FAILED,
  STATUS_SCHEDULED,
} from '@/storychief/constants/Constants';
import possibleTypes from '@/graphql/possibleTypes';
import infiniteScrollPagination from '@/graphql/utils/infiniteScrollPagination';
import clientStorage from '@/storychief/utils/clientStorage';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';
import { getISODate } from '@/date';

const postAndReferralFields = {
  editable(_, { readField }) {
    const status = readField('status');

    // We include deleted status to keep deleted posts in sync
    // with the postset settings like scheduled_at
    return status === STATUS_DRAFT || status === STATUS_DELETED;
  },
  push_status(_, { readField }) {
    if (readField('push_action')) {
      return STATUS_IDLE;
    }

    if (readField('deleted_at') && readField('unpublished_at')) {
      return STATUS_UNPUBLISHED;
    }

    if (readField('published_at') && readField('published_at') >= readField('updated_at')) {
      return STATUS_PUBLISHED;
    }

    if (readField('scheduled_at') && readField('scheduled')) {
      return STATUS_SCHEDULED;
    }

    if (readField('failed_at') >= readField('updated_at')) {
      return STATUS_FAILED;
    }

    return STATUS_DRAFT;
  },
  push_status_progress(_, { readField }) {
    return readField('push_status') === STATUS_IDLE ? 50 : 100;
  },
};

const storyDestinationFields = {
  editable(_, { readField }) {
    const status = readField('status');

    // We include deleted status to keep deleted posts in sync
    // with the postset settings like scheduled_at ??
    return status === STATUS_DRAFT || status === STATUS_DELETED;
  },
  push_status(_, { readField }) {
    if (readField('queued_at') || readField('queued_action')) {
      return STATUS_IDLE;
    }

    if (readField('deleted_at') && readField('unpublished_at')) {
      return STATUS_UNPUBLISHED;
    }

    if (readField('published_at') && readField('published_at') >= readField('updated_at')) {
      return STATUS_PUBLISHED;
    }

    if (readField('republished_at') && readField('republished_at') >= readField('updated_at')) {
      return STATUS_PUBLISHED;
    }

    if (readField('drafted_at') && readField('drafted_at') >= readField('updated_at')) {
      return STATUS_PUBLISHED;
    }

    if (readField('scheduled_at') && readField('scheduled')) {
      return STATUS_SCHEDULED;
    }

    if (readField('failed_at') >= readField('updated_at')) {
      return STATUS_FAILED;
    }

    return STATUS_DRAFT;
  },
  push_status_progress(_, { readField }) {
    return readField('push_status') === STATUS_IDLE ? 50 : 100;
  },
};

function getExistingData(typename) {
  return (existingData, { args, toReference, readField }) => {
    // Search and return data if it already exists.
    // https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
    const reference = toReference({ __typename: typename, id: args?.id });
    // Check if reference is not empty (regression from Apollo client since 3.4.8)
    const referenceData = reference && readField('id', reference) ? reference : undefined;

    return existingData || referenceData;
  };
}

export default new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        user: getExistingData('User'),
        postset: getExistingData('Postset'),
        post: getExistingData('Post'),
        referralOutreach: getExistingData('ReferralOutreach'),
        story: getExistingData('Story'),
        storyDestination: getExistingData('StoryDestination'),
        event: getExistingData('Event'),
        account: getExistingData('Account'),
        task: getExistingData('Task'),
        taskTag: getExistingData('TaskTag'),
        destination: getExistingData('Destination'),
        campaign: getExistingData('Campaign'),
        segment: getExistingData('Segment'),
        videoProject: getExistingData('VideoProject'),
        websiteContent: getExistingData('WebsiteContent'),
        newsletter: getExistingData('Newsletter'),
        webinar: getExistingData('Webinar'),
        podcast: getExistingData('Podcast'),
        ebook: getExistingData('Ebook'),
        images: infiniteScrollPagination(),
        videos: infiniteScrollPagination(),
        activities: infiniteScrollPagination(),
        notifications: infiniteScrollPagination(),
        entitlementsObject: {
          read(existingData, { readField }) {
            // Prepare and cache all the entitlements into an object for easier and more performant access.
            const entitlementsData = readField('entitlements') || [];
            const entitlements = {};

            entitlementsData.forEach((ref) => {
              const type = readField('__typename', ref);
              const key = readField('key', ref);
              const value = readField('value', ref);

              if (type === 'EntitlementBoolean') {
                entitlements[key] = true;
              } else if (type === 'EntitlementNumber') {
                entitlements[key] = value;
              }
            });

            return entitlements;
          },
        },
      },
    },
    // Policies for types that do not have an ID.
    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#configuring-merge-functions-for-types-rather-than-fields
    UserPivot: {
      merge: true,
    },
    EntitlementBoolean: {
      keyFields: ['key'],
    },
    EntitlementNumber: {
      keyFields: ['key'],
    },
    StoryPaginator: {
      merge: true,
    },
    PostsetPaginator: {
      merge: true,
    },
    TailoredStory: {
      merge: true,
    },
    TailoredLink: {
      merge: true,
    },
    CampaignPolicy: {
      merge: true,
    },
    EventPolicy: {
      merge: true,
    },
    StoryPolicy: {
      merge: true,
    },
    NewsletterPolicy: {
      merge: true,
    },
    WebsiteContentPolicy: {
      merge: true,
    },
    VideoProjectPolicy: {
      merge: true,
    },
    EbookPolicy: {
      merge: true,
    },
    WebinarPolicy: {
      merge: true,
    },
    PodcastPolicy: {
      merge: true,
    },
    PostsetPolicy: {
      merge: true,
    },
    post_policy: {
      merge: true,
    },
    story_policy: {
      merge: true,
    },
    comment_policy: {
      merge: true,
    },
    referral_outreach_policy: {
      merge: true,
    },
    segment_policy: {
      merge: true,
    },
    review_policy: {
      merge: true,
    },
    video_policy: {
      merge: true,
    },
    PaymentSubscription: {
      merge: true,
    },
    CustomFieldValue: {
      keyFields: ['custom_field_id', 'value'],
    },
    Country: {
      keyFields: ['code'],
    },
    Keyword: {
      merge: true,
    },
    KeywordPivot: {
      keyFields: ['analysis_pipeline_run_id'],
    },
    ExcludedAttachable: {
      keyFields: ['id', 'type'],
    },
    Story: {
      fields: {
        categories: {
          merge: false,
        },
        tags: {
          merge: false,
        },
        keywords: {
          merge: false,
        },
        custom_field_values: {
          merge: false,
        },
        story_destinations: {
          merge: false,
        },
        referral_outreaches: {
          merge: false,
        },
      },
    },
    StoryDestination: {
      fields: {
        ...storyDestinationFields,
      },
    },
    Postset: {
      fields: {
        attachments: {
          merge: false,
        },
        posts: {
          merge: false,
        },
        referral_outreaches: {
          merge: false,
        },
        editable(_, { readField }) {
          return readField('status') === STATUS_DRAFT;
        },
      },
    },
    ReferralOutreach: {
      fields: {
        ...postAndReferralFields,
      },
    },
    Post: {
      fields: {
        audience_restrictions: {
          merge: true,
        },
        ...postAndReferralFields,
      },
    },
    Campaign: {
      fields: {
        starts_at(existingData) {
          if (existingData) {
            return getISODate(
              getDateObjectWithTimezone(existingData).set({ hour: 0, minute: 0, second: 0 }),
            );
          }
          return existingData;
        },
        ends_at(existingData) {
          if (existingData) {
            return getISODate(
              getDateObjectWithTimezone(existingData).set({ hour: 23, minute: 59, second: 59 }),
            );
          }
          return existingData;
        },
      },
    },
  },
});

// Initialize reactive variables
export const modalsVar = makeVar([]);
export const sidebarsVar = makeVar([]);
export const storyEditorDataVar = makeVar({});
export const storyWritingTopicsMessageIndexVar = makeVar(0);
export const refetchQueriesVar = makeVar(new Set());
export const selectedCommentEditorKeyVar = makeVar(null);
export const commentsUnsavedVar = makeVar(new Set());
export const newPostsetAttachmentsVar = makeVar([]);
export const newPostAttachmentsVar = makeVar([]);
export const activePostsetMutationsVar = makeVar(new Map());
export const postsetUpdatedAtVar = makeVar(new Map());
export const destinationOwnershipPermissionsVar = makeVar({});
export const destinationConfirmActivationVar = makeVar([]);
export const calendarSettingsVar = makeVar(getCalendarSettings());
export const cardNumberElementVar = makeVar(null);
export const ibanElementVar = makeVar(null);
export const previewEnabledVar = makeVar(false);
export const previewUserSignKeyVar = makeVar(null);
export const previewModelSignKeysVar = makeVar({ Story: {}, Postset: {} });
export const previewUsersVar = makeVar(null);
export const previewCanCommentVar = makeVar(null);
export const isUserDraggingFileVar = makeVar(false);
export const activeContentItemMutationsVar = makeVar(new Map());
export const contentItemUpdatedAtVar = makeVar(new Map());
export const storyResultTopicsErrorVar = makeVar(null);

function getCalendarSettings() {
  let settings = {};
  const nodeContentCalendar = document.getElementById('js-content-calendar');
  const nodePublicCalendar = document.getElementById('js-public-calendar');
  const params = new URLSearchParams(window.location.search);

  if (nodeContentCalendar || nodePublicCalendar) {
    if (nodeContentCalendar && nodeContentCalendar.dataset.calendar) {
      settings = JSON.parse(nodeContentCalendar.dataset.calendar);
    }

    // Set defaults
    if (!clientStorage.getItem('calendar.view')) {
      clientStorage.setItem('calendar.view', Views.MONTH);
    }

    if (clientStorage.getItem('calendar.isAllDayCollapsed')) {
      clientStorage.setItem('calendar.isAllDayCollapsed', 'true');
    }

    // Override the calendar overview with the query param /free/calendar/segments/all?view=week
    // Used especially for the onboarding tours
    if (['month', 'week'].includes(params.get('view'))) {
      clientStorage.setItem('calendar.view', params.get('view'));
    }
  }

  return {
    ...settings,
    ...{
      view: clientStorage.getItem('calendar.view'),
      isAllDayCollapsed: JSON.parse(clientStorage.getItem('calendar.isAllDayCollapsed')),
    },
  };
}
