import getDateObject from '@/date/getDateObject';
import { DateRaw } from './types';

export default function getISODate(date?: DateRaw): string {
  // suppressMilliseconds strips out milliseconds only when they are set to 0.
  return getDateObject(date)
    .setZone('Europe/Brussels')
    .set({ millisecond: 0 })
    .toISO({ suppressMilliseconds: true });
}
